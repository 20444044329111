import { createAction } from "@reduxjs/toolkit";

const addUserRequest = createAction("User/addUserRequest");
const addUserSuccess = createAction("User/addUserSuccess");
const addUserError = createAction("User/addUserError");

const addUsernameRequest = createAction("User/addUsernameRequest");
const addUsernameSuccess = createAction("User/addUsernameSuccess");
const addUsernameError = createAction("User/addUsernameError");

const getQrCodeSuccess = createAction("User/getQrCodeSuccess");

const whatsAppSession = createAction("User/whatsAppSuccess");

const refreshSessionRequest = createAction("User/refreshSessionRequest");
const refreshSessionSuccess = createAction("User/refreshSessionSecces");
const refreshSessionError = createAction("User/refreshSessionError");

const getCompaniesRequest = createAction("User/getCompaniesRequest");
const getCompaniesSuccess = createAction("User/getCompaniesSecces");
const getCompaniesError = createAction("User/getCompaniesError");

const getCompaniesFromBDRequest = createAction(
  "User/getCompaniesFromBDRequest"
);
const getCompaniesFromBDSuccess = createAction(
  "User/getCompaniesFromBDSuccess"
);
const getCompaniesFromBDError = createAction("User/getCompaniesFromBDError");

export {
  addUserRequest,
  addUserSuccess,
  addUserError,
  addUsernameRequest,
  addUsernameSuccess,
  addUsernameError,
  getQrCodeSuccess,
  refreshSessionRequest,
  refreshSessionSuccess,
  refreshSessionError,
  whatsAppSession,
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesError,
  getCompaniesFromBDRequest,
  getCompaniesFromBDSuccess,
  getCompaniesFromBDError,
};
