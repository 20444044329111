import { useState, useRef, useEffect } from "react";
import style from "./SelectCompany.module.css";
import sprite from "../../img/sprite.svg";

const SelectCompany = ({
  width,
  chooseCompany,
  selectedCompany,
  arrCompaigns,
  showAllCompanies,
  display,
}) => {
  const [isOpenList, setIsOpenList] = useState(false);
  const rootEl = useRef(null);

  useEffect(() => {
    const onClick = (e) =>
      rootEl.current?.contains(e.target) || setIsOpenList(false);
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  const togleListCompanies = () => {
    setIsOpenList(!isOpenList);
  };

  return (
    <div ref={rootEl}>
      <div
        className={style.select}
        id="select"
        onClick={togleListCompanies}
        style={{ width: [width] }}
      >
        <p className={style.text}>{selectedCompany?.name || "All Campaigns"}</p>
        <svg className={style.down}>
          <use href={sprite + "#icon-down"}></use>
        </svg>
        {isOpenList && (
          <ul className={style.listCompamies}>
            <li
              style={display}
              onClick={showAllCompanies}
              className={style.listItem}
            >
              All Campaigns
            </li>
            {arrCompaigns.map((company) => (
              <li
                id={company.id}
                onClick={chooseCompany}
                key={company.id}
                className={style.listItem}
              >
                {company.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectCompany;
