import { createReducer } from "@reduxjs/toolkit";

import {
  addUserRequest,
  addUserSuccess,
  addUsernameRequest,
  addUsernameSuccess,
  addUsernameError,
  getQrCodeSuccess,
  addUserError,
  refreshSessionRequest,
  refreshSessionSuccess,
  refreshSessionError,
  whatsAppSession,
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesError,
  getCompaniesFromBDRequest,
  getCompaniesFromBDSuccess,
  getCompaniesFromBDError,
} from "./user.actions";

export const userIdReducer = createReducer([], {
  [addUserSuccess]: (_, { payload }) => payload,
  [addUsernameSuccess]: (_, { payload }) => payload,
});


export const qrCodeReducer = createReducer([], {
  [getQrCodeSuccess]: (_, { payload }) => payload,
});

export const refreshSessionReducer = createReducer([], {
  [refreshSessionSuccess]: (_, { payload }) => payload,
});

export const userConflict = createReducer([], {
  [addUserError]: (_, { payload }) => payload,
});

export const whatsApp = createReducer([], {
  [whatsAppSession]: (_, { payload }) => payload,
});

export const companiesUser = createReducer([], {
  [getCompaniesSuccess]: (_, { payload }) => payload,
});

export const companiesUserFromBd = createReducer([], {
  [getCompaniesFromBDSuccess]: (_, { payload }) => payload,
});

export const loading = createReducer(false, {
  [addUserRequest]: () => true,
  [addUsernameRequest]: () => true,
  [addUserSuccess]: () => false,
  [addUsernameSuccess]: () => false,
  [addUserError]: () => false,
  [addUsernameError]: () => false,
  [refreshSessionRequest]: () => true,
  [refreshSessionSuccess]: () => false,
  [refreshSessionError]: () => false,
  [getCompaniesRequest]: () => true,
  [getCompaniesSuccess]: () => false,
  [getCompaniesError]: () => false,
  [getCompaniesFromBDRequest]: () => true,
  [getCompaniesFromBDSuccess]: () => false,
  [getCompaniesFromBDError]: () => false,
  
});
