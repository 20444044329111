export function isValidMail(myMail) {
  return /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(
    myMail
  );
}
export function placeCaretAtEnd(el) {
  el.focus();
  if (
    typeof window.getSelection != "undefined" &&
    typeof document.createRange != "undefined"
  ) {
    var range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != "undefined") {
    var textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}
export function updateTagsStyle(tagify) {
  tagify.getTagElms().forEach((tagElm) => {
    tagElm.style =
      "--tag-text-color: #2B3135; --tag-remove-btn-color: #2B3135; --tag-bg: #F2F3F4; --tag-pad: 2px 8px 2px 8px ";
  });
}
export function tagifyParseReverse(text) {
  let baseMessage = text;
  baseMessage = baseMessage.replaceAll("{{Name}}", '[[{"value":"Name"}]]');
  baseMessage = baseMessage.replaceAll(
    "{{Appointment_Time}}",
    '[[{"value":"Appointment_Time"}]]'
  );
  baseMessage = baseMessage.replaceAll(
    "{{Appointment_Date}}",
    '[[{"value":"Appointment_Date"}]]'
  );

  return baseMessage;
}
export function templateParseToTagify(message) {
  // console.log(message);
  const matches = [...message.matchAll(/(\[\[\{)(.+?)(\}\]\])/gm)];
  // console.log(matches[0]);
  if (matches.length === 0) {
    return message;
  }
  for (const match of matches) {
    const data = JSON.parse(`{${match[2]}}`);
    message = message.replace(match[0], `{{${data.value}}}`);
  }
  // console.log(message);
  return message;
}
