import { useState, useEffect } from "react";
import style from "./Messages.module.css";
import Message from "./Message";
import { editMessage } from "../../services/api";
import { useDispatch } from "react-redux";
import { getCompaniesFromBDOperation } from "../../redux/user/user.operation";
import RemoveModal from "../modal/RemoveModal";
import AddModal from "../modal/AddModal";

const Messages = ({ messages, companyId }) => {
  const [currentMessage, setCurrentMessage] = useState(null);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    return () => {
      setIsOpenModalDelete(false);
      setIsOpenEditModal(false);
    };
  }, []);
  const openModalDelete = (msg) => {
    setIsOpenModalDelete(!isOpenModalDelete);
    setMsg(msg);
  };

  const switchEditModal = (msg) => {
    setIsOpenEditModal(!isOpenEditModal);
    setMsg(msg);
  };

  const dispatch = useDispatch();
  const sortMessages = [...messages].sort((a, b) => a.position - b.position);
  const dragStartHandler = (e, message) => {
    setCurrentMessage(message);
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
  };
  const dropHandler = async (e, message) => {
    e.preventDefault();
    const data = {
      companyId,
      id: message.id,
      title: message.title,
      message: message.message,
      position: currentMessage.position,
    };
    await editMessage(data);
    const CurrentData = {
      companyId,
      id: currentMessage.id,
      title: currentMessage.title,
      message: currentMessage.message,
      position: message.position,
    };
    await editMessage(CurrentData);
    dispatch(getCompaniesFromBDOperation());
  };

  return (
    <>
      <ul>
        {sortMessages.map((message) => (
          <li
            onDragStart={(e) => dragStartHandler(e, message)}
            onDragOver={(e) => dragOverHandler(e)}
            onDrop={(e) => dropHandler(e, message)}
            draggable={true}
            key={message.id}
            className={style.listFlex}
          >
            <Message
              switchEditModal={switchEditModal}
              openModalDelete={openModalDelete}
              position={message.position}
              message={message}
              companyId={companyId}
            />
          </li>
        ))}
      </ul>
      {isOpenModalDelete && (
        <RemoveModal
          draggable={false}
          closeModal={openModalDelete}
          name={"Message"}
          message={msg}
          companyId={companyId}
        />
      )}
      {isOpenEditModal && (
        <AddModal
          draggable={false}
          name={"Edit Message"}
          togleModal={switchEditModal}
          display={{ display: "none" }}
          companyId={companyId}
          messageEdit={msg}
        />
      )}
    </>
  );
};

export default Messages;
