import QRCode from "react-qr-code";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import style from "./QrCode.module.css";
import { getQrCode, whatsAppStatus } from "../../redux/user/user.selectors";
import Spiner from "../spiner/Spiner";
import { connect, qrClient } from "../../services/socket";
import sprite from "../../img/sprite.svg";

const QrCodeClient = () => {
  const qr = useSelector(getQrCode);
  const statusWhatsApp = useSelector(whatsAppStatus);
  const getParams = () => window.location.search;
  const id = getParams().slice(8);

  useEffect(() => {
    connect(id);
    qrClient(id, true);
  }, [id]);

  return (
    <div className={style.qr}>
      {qr.length || statusWhatsApp === "active" ? (
        qr === "Client is ready!" || statusWhatsApp === "active" ? (
          <div className={style.status}>
            <svg className={style.icon}>
              <use href={sprite + "#icon-left-right"}></use>
            </svg>
            <h2 className={style.header}>WhatsApp has been connected</h2>
          </div>
        ) : (
          <QRCode value={qr} />
        )
      ) : (
        <Spiner style={{ color: "black", fontSize: "4em" }} />
      )}
    </div>
  );
};

export default QrCodeClient;
