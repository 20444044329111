import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import sprite from "../../img/sprite.svg";
import style from "./AuthConnect.module.css";
import Header from "../header/Header";
import BtnBlue from "../buttons/BtnBlue";
import BtnWhite from "../buttons/BtnWhite";
import Container from "../container/Container";
import {
  getUserId,
  whatsAppStatus,
  getLoading,
} from "../../redux/user/user.selectors";
import { URL_HEROKU, URL_LOCAL_CLIENT } from "../../helpers/Constants";
import { styleBtn } from "./styleBtnConnect";
import Spiner from "../spiner/Spiner";

const AuthConnect = () => {
  const [showToolTip, setShowToolTip] = useState(false);
  const statusWhatsApp = useSelector(whatsAppStatus);
  const loading = useSelector(getLoading);
  const user = useSelector(getUserId);

  let link = `${URL_HEROKU}/qrClient?userId=${user._id}`;

  const saveLink = () => {
    setShowToolTip(true);
    navigator.clipboard.writeText(link);
    setTimeout(() => setShowToolTip(false), 3000);
  };

  return (
    <>
      <Header />
      <Container>
        {loading || statusWhatsApp.length === 0 ? (
          <div className={style.flexSpiner}>
            <Spiner style={{ color: "black", fontSize: "4em" }} />
          </div>
        ) : (
          <>
            {statusWhatsApp === "active" ? (
              <>
                <svg className={style.icon}>
                  <use href={sprite + "#icon-left-right"}></use>
                </svg>
                <h2 className={style.header}>WhatsApp has been connect</h2>
              </>
            ) : (
              <>
                <svg className={style.icon}>
                  <use href={sprite + "#icon-stop"}></use>
                </svg>
                <h2 className={style.header}>
                  WhatsApp has been disconnected
                </h2>
              </>
            )}

            <div className={style.flex}>
              <Link to={"/qr"}>
                <BtnWhite
                  icon={"#icon-send"}
                  name={"Open QR code"}
                  iconStyle={styleBtn.iconStyleOpen}
                  btnStyle={styleBtn.btnStyleOpen}
                />
              </Link>

              <div onClick={saveLink} className={style.btnCopyLink}>
                <BtnBlue
                  icon={"#icon-link"}
                  name={"Copy Link"}
                  iconStyle={styleBtn.iconStyleCopy}
                  btnStyle={styleBtn.btnStyleCopy}
                />
                <div
                  style={showToolTip ? null : { display: "none" }}
                  className={style.tooltip}
                >
                  <svg className={style.iconTooltip}>
                    <use href={sprite + "#icon-agry"}></use>
                  </svg>
                  <p className={style.textTooltip}>Link Copied</p>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>

      {/* {statusWhatsApp === "active" || statusWhatsApp === "removing" ? (
        <Navigate to="/companies" />
      ) : (
        <>
          
        </>
      )} */}
    </>
  );
};

export default AuthConnect;