import { useSelector } from "react-redux";
import { getSession } from "../../redux/user/user.selectors";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {

  const isAuth = useSelector(getSession);
  return isAuth === "AUTHORIZED" ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
