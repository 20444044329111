import { useEffect, useState } from "react";
import style from "./AuthForm.module.css";
import sprite from "../../img/sprite.svg";
import { isValidMail } from "../../helpers/helpers";
import { addUserOperation } from "../../redux/user/user.operation";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMsg, getLoading } from "../../redux/user/user.selectors";
import BtnBlue from "../buttons/BtnBlue";
import { stylebtnSign } from "./styleBtnSign";
import Container from "../container/Container";
import { addUserError } from "../../redux/user/user.actions";
import Spiner from "../spiner/Spiner";

const AuthForm = () => {
  const [apiKey, setApiKey] = useState("");
  const [email, setEmail] = useState("");
  const [validMail, setValidMail] = useState();
  const [emailError, setEmailError] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(null);
  const dispatch = useDispatch();

  const errorMsg = useSelector(getErrorMsg);
  useEffect(() => {
    if (errorMsg?.includes("Email")) {
      setEmailError(errorMsg);
    } else if (errorMsg?.includes("API")) {
      setApiKeyError(errorMsg);
    }
    setTimeout(() => {
      setEmailError(null);
      setApiKeyError(null);
      dispatch(addUserError(null));
    }, 3000);
  }, [errorMsg, dispatch]);

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value);
    setValidMail(isValidMail(e.currentTarget.value));
    setEmailError(null);
  };

  const handleApiKey = (e) => {
    setApiKey(e.currentTarget.value);
    setApiKeyError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addUserOperation({ email, apiKey }));
    setEmail("");
    setApiKey("");
  };

  const loading = useSelector(getLoading);

  return (
    <Container>
      {loading ? (
        <div className={style.flexSpiner}>
          <Spiner style={{ color: "black", fontSize: "4em" }} />
        </div>
      ) : (
        <div className={style.authForm}>
          <svg className={style.logo}>
            <use href={sprite + "#icon-Logo-auth"}></use>
          </svg>
          <h2 className={style.header}>Welcome to Wave Marketing</h2>
          <form onSubmit={handleSubmit} className={style.form}>
            <h3 className={style.formHeader}>Sign into your GHL account</h3>
            <label className={style.label}>
              {" "}
              <span
                style={
                  apiKey.length && apiKey.length < 16
                    ? { color: "#ff8616" }
                    : null
                }
                className={style.nameField}
              >
                API Key
              </span>
              <input
                name="apiKey"
                type="apiKey"
                value={apiKey}
                onChange={handleApiKey}
                style={
                  apiKey.length && apiKey.length < 16
                    ? { border: "1px solid #ff8616" }
                    : { color: "#2b3135", border: "1px solid #d3d5da" }
                }
                className={style.input}
              ></input>
              {apiKey.length && apiKey.length < 16 ? (
                <div className={style.warning}>
                  <svg className={style.iconError}>
                    <use href={sprite + "#icon-error"}></use>
                  </svg>
                  <span>Enter a valid API KEY</span>
                </div>
              ) : null}
              {apiKeyError?.length && (
                <div className={style.warning}>
                  <svg className={style.iconError}>
                    <use href={sprite + "#icon-error"}></use>
                  </svg>
                  <span>{apiKeyError}</span>
                </div>
              )}
            </label>
            <label className={style.label}>
              {" "}
              <span
                style={
                  email.length
                    ? validMail
                      ? null
                      : { color: "#ff8616" }
                    : null
                }
                className={style.nameField}
              >
                Email
              </span>
              <input
                name="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                style={
                  email.length
                    ? validMail
                      ? { border: "1px solid #d3d5da", color: "#2b3135" }
                      : { border: "1px solid #ff8616" }
                    : null
                }
                className={style.input}
              ></input>
              {email.length ? (
                validMail ? null : (
                  <div className={style.warning}>
                    <svg className={style.iconError}>
                      <use href={sprite + "#icon-error"}></use>
                    </svg>
                    <span>Enter a valid Email. Use format xxxxx@xxxx.xxx</span>
                  </div>
                )
              ) : null}
              {emailError?.length && (
                <div className={style.warning}>
                  <svg className={style.iconError}>
                    <use href={sprite + "#icon-error"}></use>
                  </svg>
                  <span>{emailError}</span>
                </div>
              )}
            </label>
            <div className={style.btn}>
              <BtnBlue
                disable={!(validMail && apiKey.length > 15)}
                name={"Sign In"}
                iconStyle={{ display: "none" }}
                btnStyle={
                  apiKey.length >= 16 && validMail
                    ? stylebtnSign.active
                    : stylebtnSign.disable
                }
              />
            </div>
          </form>
        </div>
      )}
    </Container>
  );
};

export default AuthForm;
