import React, { useRef, useState, useCallback, createElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./AddModal.module.css";
import sprite from "../../img/sprite.svg";
import SelectCompany from "../companies/SelectCompany";
import BtnGrey from "../buttons/BtnGrey";
import BtnWhite from "../buttons/BtnWhite";
import BtnBlue from "../buttons/BtnBlue";
import { styleBtn } from "./styleBtnModal";
import {
  getCompanies,
  getCompaniesFromBd,
} from "../../redux/user/user.selectors";
import { saveCompanyAndMsg, editMessage } from "../../services/api";
import { getCompaniesFromBDOperation } from "../../redux/user/user.operation";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import {
  placeCaretAtEnd,
  updateTagsStyle,
  tagifyParseReverse,
  templateParseToTagify,
} from "../../helpers/helpers";
import { useEffect } from "react";

const AddModal = ({
  name,
  togleModal,
  display,
  company,
  messageEdit,
  companyId,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(company);
  const [titleMessage, setTitleMessage] = useState(messageEdit?.title || "");
  const [message, setMessage] = useState(messageEdit?.message || "");
  const dispatch = useDispatch();
  const arrCompanies = useSelector(getCompanies);
  const arrCompainesFromBd = useSelector(getCompaniesFromBd);
  const textareaRef = useRef();

  useEffect(() => {
    const parsedMessage = tagifyParseReverse(message);
    textareaRef.current.updateValueByDOMTags();
    textareaRef.current.removeAllTags();
    textareaRef.current.parseMixTags(parsedMessage);
    updateTagsStyle(textareaRef.current);
  }, [messageEdit]);

  const chooseCompany = (e) =>
    setSelectedCompany(
      arrCompanies.find((company) => company.id === e.currentTarget.id)
    );

  const handleChange = (e) => {
    if (e.currentTarget.name === "title") {
      setTitleMessage(e.currentTarget.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (messageEdit) {
      const data = {
        companyId,
        id: messageEdit.id,
        title: titleMessage,
        message: message,
        position: messageEdit.position,
      };

      await editMessage(data);
      dispatch(getCompaniesFromBDOperation());
      await togleModal();
      return;
    }
    const companyFromBd = arrCompainesFromBd.find(
      (company) => company.id === selectedCompany.id
    );
    const companies = {
      company: selectedCompany,
      message: {
        title: titleMessage,
        message: message,
        position: companyFromBd?.messages?.length + 1 || 1,
      },
    };
    await saveCompanyAndMsg(companies);
    dispatch(getCompaniesFromBDOperation());
    togleModal();
  };

  const addName = (e) => {
    e.preventDefault();
    textareaRef.current.addMixTags("Name");
    placeCaretAtEnd(textareaRef.current.DOM.input);
    const msg = templateParseToTagify(
      textareaRef.current.DOM.originalInput.value
    );
    setMessage(msg.replace(new RegExp("\\r?", "g"), ""));
    updateTagsStyle(textareaRef.current);
  };

  const addTime = (e) => {
    e.preventDefault();
    textareaRef.current.addMixTags("Appointment_Time");
    placeCaretAtEnd(textareaRef.current.DOM.input);
    const msg = templateParseToTagify(
      textareaRef.current.DOM.originalInput.value
    );
    setMessage(msg.replace(new RegExp("\\r?", "g"), ""));
    updateTagsStyle(textareaRef.current);
  };

  const addDate = (e) => {
    e.preventDefault();
    textareaRef.current.addMixTags("Appointment_Date");
    placeCaretAtEnd(textareaRef.current.DOM.input);
    const msg = templateParseToTagify(
      textareaRef.current.DOM.originalInput.value
    );
    setMessage(msg.replace(new RegExp("\\r?", "g"), ""));
    updateTagsStyle(textareaRef.current);
  };

  const onChangeTags = useCallback(() => {
    console.log(textareaRef.current.DOM.originalInput.value)
    const msg = templateParseToTagify(
      textareaRef.current.DOM.originalInput.value.replace(
        new RegExp("", "g"),
        ""
      )
    );
    setMessage(msg);
  }, []);

  return (
    <div className={style.bgModal}>
      <form onSubmit={handleSubmit} className={style.form}>
        <svg onClick={togleModal} className={style.icon}>
          <use href={sprite + "#icon-close"}></use>
        </svg>
        <h2 className={style.head}>{name}</h2>
        <div style={display}>
          <p>Campaign</p>
          <SelectCompany
            display={{ display: "none" }}
            width={"320px"}
            arrCompaigns={arrCompanies}
            chooseCompany={chooseCompany}
            selectedCompany={selectedCompany}
          />
        </div>
        <label className={style.label}>
          Message Title
          <input
            onChange={handleChange}
            className={style.input}
            name="title"
            type="text"
            value={titleMessage}
          ></input>
        </label>
        <div className={style.labelMsg}>
          Message
          <MixedTags
            className={style.inputMsg}
            InputMode="textarea"
            tagifyRef={textareaRef}
            onChange={onChangeTags}
            onInput={onChangeTags}
            autoFocus={true}
            name="message"
            type="text"
            defaultValue={message}
            settings={{
              mode: "mix",
              duplicates: true,
              trim: true,
              mixMode: {
                insertAfterTag: " ",
              },
            }}
          ></MixedTags>
          <div className={style.btnInput} onClick={addName}>
            <BtnGrey
              name={"Name"}
              iconStyle={{ display: "none" }}
              btnStyle={styleBtn.btnName}
            />
          </div>
          <div className={style.btnInput} onClick={addTime}>
            <BtnGrey
              name={"Appointment Time"}
              iconStyle={{ display: "none" }}
              btnStyle={styleBtn.btnTime}
            />
          </div>
          <div className={style.btnInput} onClick={addDate}>
            <BtnGrey
              name={"Appointment Date"}
              iconStyle={{ display: "none" }}
              btnStyle={styleBtn.btnDate}
            />
          </div>
        </div>
        <div className={style.btnFlex}>
          <div onClick={togleModal}>
            <BtnWhite
              name={"Cancel"}
              iconStyle={{ display: "none" }}
              btnStyle={styleBtn.btnCancel}
            />
          </div>
          <BtnBlue
            type="submit"
            disable={
              !(titleMessage && message && (selectedCompany || companyId))
            }
            name={"Save"}
            iconStyle={{ display: "none" }}
            btnStyle={
              titleMessage && message && (selectedCompany || companyId)
                ? styleBtn.btnSave
                : styleBtn.btnDisable
            }
          />
        </div>
      </form>
    </div>
  );
};

export default AddModal;
