export const styleBtn = {
    iconStyleEdit: {
      width: "13px",
      height: "13px",
      marginRight: "6px",
      fill: "#4781E5",
    },
  
    btnStyleEdit: {
      width: "auto",
      height: "auto",
      border: "#fff",
      fontSize: "12px",
      color: "#4781E5",
    },
  
    iconStyleCopy: {
      width: "14px",
      height: "8px",
      marginRight: "6px",
      fill: "#4781E5",
    },
  
    iconStyleDel: {
      width: "10px",
      height: "10px",
      marginRight: "7px",
      fill: "#8696A0",
    },
  
    btnStyleCopy: {
      width: "88px",
      height: "28px", 
      marginRight: "16px",
      fontSize: "12px",
      color: "#4781E5",
      border: "#fff"
    },
  
    btnStyleDel: {
      width: "132px",
      height: "28px",
      marginRight: "16px",
      fontSize: "12px",
      color: "#4781E5",
      border: "#fff"
    },
  };
  