import QRCode from "react-qr-code";
import {useEffect} from 'react'
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import style from "./QrCode.module.css";
import { getQrCode, whatsAppStatus, getUserId } from "../../redux/user/user.selectors";
import Spiner from "../spiner/Spiner";
import {qrClient} from "../../services/socket"

const QrCodeReact = () => {
  const qr = useSelector(getQrCode);
  const statusWhatsApp = useSelector(whatsAppStatus);
  const user = useSelector(getUserId);
  
  useEffect(() => {
    qrClient(user._id, false);
  }, []);
  return (
    
    <div className={style.qr}>
      {
        qr === "Client is ready!" || statusWhatsApp === "active" ? (
          <Navigate to="/companies" />
        ) : (
          qr.length ? (
            <QRCode value={qr} />
          ) : (
            <Spiner style={{ color: "black", fontSize: "4em" }} />
          )
        )
      }
    </div>
  );
};

export default QrCodeReact;
