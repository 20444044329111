export const styleBtn = {
  btnName: {
    width: "57px",
    height: "34px",
    color: "#2B3135",
    fontSize: "12px",
    position: "absolute",
    bottom: "16px",
    left: "8px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    background: "#f2f3f4",
    zIndex: 10,
  },
  btnTime: {
    width: "90px",
    height: "34px",
    color: "#2B3135",
    fontSize: "12px",
    position: "absolute",
    bottom: "16px",
    left: "80px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    background: "#f2f3f4",
    zIndex: 10,
  },
  btnDate: {
    width: "90px",
    height: "34px",
    color: "#2B3135",
    fontSize: "12px",
    position: "absolute",
    bottom: "16px",
    left: "185px",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    background: "#f2f3f4",
    zIndex: 10,
  },
  btnCancel: { width: "148px", height: "48px" },
  btnSave: { width: "148px", height: "48px", color: "#fff" },
  btnDisable: {
    width: "148px",
    height: "48px",
    color: "#fff",
    background: "#d3d5da",
    border: "1px solid #d3d5da",
  },
};