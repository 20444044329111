import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import style from "./Company.module.css";
import BtnGrey from "../buttons/BtnGrey";
import BtnBlue from "../buttons/BtnBlue";
import Messages from "../messages/MessagesList";
import { styleBtn } from "./styleBtnCompany";
import RemoveModal from "../modal/RemoveModal";
import AddModal from "../modal/AddModal";
import { getCompaniesFromBd } from "../../redux/user/user.selectors";

const Company = ({ company }) => {
  const [isOpenRemove, setIsOpenRemove] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const togleRemove = () => setIsOpenRemove(!isOpenRemove);
  const togleAdd = () => setIsOpenAdd(!isOpenAdd);

  useEffect(() => {
    return () => {
      setIsOpenAdd(false);
      setIsOpenRemove(false);
    };
  }, []);

  const arrCompainesFromBd = useSelector(getCompaniesFromBd);
  let messages = null;
  const findCompany = arrCompainesFromBd.find(
    (companyBd) => companyBd.id === company.id
  );
  if (findCompany?.messages) {
    messages = findCompany.messages;
  }
  return (
    <>
      <div className={style.listCompany}>
        <div className={style.flexComp}>
          <h1 className={style.headText}>{company.name}</h1>
          <div className={style.flexBtn}>
            <div onClick={togleRemove}>
              <BtnGrey
                name={"Remove Campaign"}
                icon={"#icon-close"}
                iconStyle={styleBtn.iconStyleDel}
                btnStyle={styleBtn.btnStyleDel}
              />
            </div>
            <div onClick={togleAdd}>
              <BtnBlue
                icon={"#icon-add"}
                name={"Add Message"}
                iconStyle={styleBtn.iconStyleAddMsg}
                btnStyle={styleBtn.btnStyleAddMsg}
              />
            </div>
          </div>
        </div>
        {messages && <Messages companyId={company.id} messages={messages} />}
        {isOpenRemove && (
          <RemoveModal
            name={"Campaign"}
            closeModal={togleRemove}
            companyId={company.id}
          />
        )}
        {isOpenAdd && (
          <AddModal
            name={"Add New Message"}
            company={company}
            togleModal={togleAdd}
            display={{ display: "none" }}
          />
        )}
      </div>
    </>
  );
};

export default Company;
