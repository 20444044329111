import { useEffect, useState } from "react";
import style from "./Username.module.css";
import sprite from "../../img/sprite.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { isValidMail } from "../../helpers/helpers";
import { addUsernameOperation } from "../../redux/user/user.operation";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMsg, getLoading, getUserId } from "../../redux/user/user.selectors";
import BtnBlue from "../buttons/BtnBlue";
import { stylebtnSign } from "./styleBtnSign";
import Container from "../container/Container";
import { addUserError } from "../../redux/user/user.actions";
import Spiner from "../spiner/Spiner";
import Header from "../header/Header";

const Username = () => {
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const user = useSelector(getUserId);
  const navigate = useNavigate()

  useEffect(() => {
    if(user.username){
      navigate('/companies')
    }
  }, [user, dispatch]);

  const handleUsername = (e) => {
    setUsername(e.currentTarget.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addUsernameOperation({ id: user._id, username }));
    setUsername("");
  };

  const loading = useSelector(getLoading);

  return (
    <>
    <Header />
    <Container>
      {loading ? (
        <div className={style.flexSpiner}>
          <Spiner style={{ color: "black", fontSize: "4em" }} />
        </div>
      ) : (
        <div className={style.authForm}>
          <form onSubmit={handleSubmit} className={style.form}>
            <h3 className={style.formHeader}>Enter your GHL account name</h3>
            <label className={style.label}>
              {" "}
              <span
                style={username.length <= 0
                  ? { color: "#ff8616" }
                  : null}
                className={style.nameField}
              >
                Name
              </span>
              <input
                name="username"
                type="username"
                value={username}
                onChange={handleUsername}
                style={username.length <= 0
                  ? { border: "1px solid #ff8616" }
                  : { color: "#2b3135", border: "1px solid #d3d5da" }}
                className={style.input}
              ></input>
            </label>
            <div className={style.btn}>
              <BtnBlue
                disable={!(username.length > 0)}
                name={"Save"}
                iconStyle={{ display: "none" }}
                btnStyle={username.length > 0
                  ? stylebtnSign.active
                  : stylebtnSign.disable} />
            </div>
          </form>
        </div>
      )}
    </Container>
    </>
  );
};

export default Username;
