import { useSelector } from "react-redux";
import { getSession } from "../../redux/user/user.selectors";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {

  const isAuth = useSelector(getSession);

  return isAuth === "AUTHORIZED" ? <Navigate to="/companies"/> : <Outlet />;
};

export default PublicRoute;
