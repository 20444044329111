import sprite from "../../img/sprite.svg";
import style from "./BtnBlue.module.css";

const BtnBlue = ({ icon, name, iconStyle, btnStyle, disable }) => {
  return (
    <button disabled={disable} style={btnStyle} className={style.flex}>
      <svg style={iconStyle} className={style.icon}>
        <use href={sprite + [icon]}></use>
      </svg>
      <p>{name}</p>
    </button>
  );
};

export default BtnBlue;
