import style from "./Messages.module.css";
import sprite from "../../img/sprite.svg";
import BtnWhite from "../buttons/BtnWhite";
import { URL_HEROKU, URL_LOCAL } from "../../helpers/Constants";
import { useSelector } from "react-redux";
import { getUserId } from "../../redux/user/user.selectors";
import { styleBtn } from "./styleBtn";

const Message = ({
  position,
  message,
  companyId,
  switchEditModal,
  openModalDelete,
}) => {
  const user = useSelector(getUserId);
  let link = `${URL_HEROKU}/api/user/sendWebhook?userId=${user._id}&campaignId=${companyId}&messageId=${message.id}`;

  const copyLink = async () => {
    console.log("copy", link);
    await navigator.clipboard.writeText(link);
  };

  return (
    <>
      <div className={style.wrapper}>
        <p className={style.number}>{position}</p>
        <svg className={style.iconMore}>
          <use href={sprite + "#icon-more"}></use>
        </svg>
        <div>
          <div className={style.head}>
            <h2 className={style.titleText}>{message.title}</h2>
            <div className={style.ftexBtn}>
              <div onClick={() => openModalDelete(message)}>
                <BtnWhite
                  name={"Remove Message"}
                  icon={"#icon-delete"}
                  iconStyle={styleBtn.iconStyleDel}
                  btnStyle={styleBtn.btnStyleDel}
                />
              </div>
              <div onClick={copyLink}>
                <BtnWhite
                  name={"Copy Link"}
                  icon={"#icon-link"}
                  iconStyle={styleBtn.iconStyleCopy}
                  btnStyle={styleBtn.btnStyleCopy}
                />
              </div>
              <div onClick={ () => switchEditModal(message)}>
                <BtnWhite
                  name={"Edit"}
                  icon={"#icon-edit"}
                  iconStyle={styleBtn.iconStyleEdit}
                  btnStyle={styleBtn.btnStyleEdit}
                />
              </div>
            </div>
          </div>
          <p className={style.text}>{message.message}</p>
        </div>
        <br/>
      </div>
      <input className={style.input} type={'text'} value={link}/>
    </>
  );
};

export default Message;
