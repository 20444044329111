import { useDispatch } from "react-redux";
import { getCompaniesFromBDOperation } from "../../redux/user/user.operation";
import style from "./RemoveModal.module.css";
import sprite from "../../img/sprite.svg";
import { deleteMessageOrCompany } from "../../services/api";

const RemoveModal = ({ name, closeModal, message, companyId }) => {
  const dispatch = useDispatch();

    const deleteMsg = async (e) => {
      e.preventDefault();
      const data = {
        companyId,
        message,
        name,
      };
      await deleteMessageOrCompany(data);
      dispatch(getCompaniesFromBDOperation());
      closeModal();
    };



  return (
    <div className={style.bgModal}>
    <div className={style.flexModal}>
      <h2 className={style.head}>Remove {name}</h2>
      <svg onClick={closeModal} className={style.icon}>
        <use href={sprite + "#icon-close"}></use>
      </svg>
      <p className={style.text}>Are you sure you want to remove the {name}?</p>
      <div className={style.flexBtn}>
        <div onClick={closeModal} className={style.cancel}>
          <p>Cancel</p>
        </div>
        <div onClick={deleteMsg} className={style.remove}>
          <p>Remove</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default RemoveModal;
