import sprite from "../../img/sprite.svg";
import style from "./Header.module.css";
import Spiner from "../spiner/Spiner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { whatsAppStatus, getUserId } from "../../redux/user/user.selectors";
import { logoutOperation , deleteWhatsAppSessionOperation} from "../../redux/user/user.operation";
import { disconnectWhatsApp } from "../../services/socket";
// import { deleteSessionWhatsApp } from "../../services/api";
import { whatsAppSession } from "../../redux/user/user.actions";

const Header = () => {
  const status = useSelector(whatsAppStatus);
  const user = useSelector(getUserId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(logoutOperation());
  };
  const disconnectWhatsAppEvent = () => {
    console.log(user);
    dispatch(whatsAppSession("removing"))
    disconnectWhatsApp(user._id)
  };
  const connectWhatsApp = () => {
    navigate('/connect')
  };
  const home = () => {
    navigate('/companies')
  };

  return (
    <div className={style.header}>
      <svg className={style.logoHead} onClick={home}>
        <use href={sprite + "#icon-logo-header"}></use>
      </svg>
      <div className={style.rightSide}>
        {user.username && 
          (
            <>
              {status === "removing" && (
                <div className={style.flexSpiner}>
                  <Spiner /> <p  className={style.loader}> Removing session</p>
                  {/* <p onClick={disconnectWhatsApp} className={style.disconnect}>
                    Disconnect
                  </p> */}
                </div>
              )}
              {status === "active" && (
                <div className={style.whatsAppSetting}>
                  <svg className={style.whatsAppIcon}>
                    <use href={sprite + "#icon-Whats-app"}></use>
                  </svg>
                  <p className={style.connect}>WhatsApp Connected</p>
                  <p onClick={disconnectWhatsAppEvent} className={style.disconnect}>
                    Disconnect
                  </p>
                </div>
              )}
              {status === "inactive" && (
                <div className={style.whatsAppSetting}>
                  <svg className={style.whatsAppIcon}>
                    <use href={sprite + "#icon-Whats-app-dis"}></use>
                  </svg>
                  <p className={style.dconnect}>WhatsApp Disconnected</p>
                  <p onClick={connectWhatsApp} className={style.disconnect}>
                    Connect
                  </p>
                </div>
              )}
            </>
          )
        }
        
        <svg onClick={() => logout()} className={style.logOut}>
          <use href={sprite + "#icon-logout"}></use>
        </svg>
      </div>
    </div>
  );
};

export default Header;
