import axios from "axios";

// axios.defaults.baseURL = "http://localhost:4000";

axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};
const config = { withCredentials: true };

export const getAllUsers = () =>
  axios
    .get("/api/user", config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const postUser = (data) =>
  axios
    .post("/api/user/", data, config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const postUsername = (data) =>
  axios
    .post("/api/user/username", data, config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const refreshSession = () =>
  axios
    .get("/api/user/session", config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const logout = () =>
  axios
    .get("/api/user/logout", config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const deleteSessionWhatsApp = () =>
  axios
    .get("/api/user/delete-session-whats-app", config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const getCompaniesFromBd = () =>
  axios
    .get("/api/companies/bd", config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const getAllCompanies = () =>
  axios
    .get("/api/companies", config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const saveCompanyAndMsg = (data) =>
  axios
    .patch("/api/companies/add-company-msg", data, config)
    .then((response) => response.data)
    .catch((e) => console.log(e));

export const deleteMessageOrCompany = (data) =>
  axios
    .patch("/api/companies/delete-msg-company", data, config)
    .then((response) => response.data)
    .catch((e) => console.log(e));
    
export const editMessage = (data) =>
  axios
    .patch("/api/companies/edit-msg", data, config)
    .then((response) => response.data)
    .catch((e) => console.log(e));
