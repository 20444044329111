import { disconnect } from "../../services/socket";
import {
  postUser,
  postUsername,
  refreshSession,
  logout,
  getAllCompanies,
  getCompaniesFromBd,
  deleteSessionWhatsApp,
} from "../../services/api";
import {
  addUserRequest,
  addUserSuccess,
  addUserError,
  addUsernameRequest,
  addUsernameSuccess,
  addUsernameError,
  refreshSessionRequest,
  refreshSessionSuccess,
  refreshSessionError,
  getQrCodeSuccess,
  whatsAppSession,
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesError,
  getCompaniesFromBDRequest,
  getCompaniesFromBDSuccess,
  getCompaniesFromBDError,
} from "./user.actions";

export const addUserOperation =
  ({ email, apiKey }) =>
  (dispatch) => {
    dispatch(addUserRequest());
    postUser({ email, apiKey })
      .then((result) => {
        if (result.code === 409) {
          dispatch(addUserError(result.message));
        } else {
          dispatch(addUserSuccess(result.data));
          dispatch(refreshSessionSuccess(result.message));
        }
      })
      .catch((error) => {
        dispatch(addUserError(error.message));
      });
  };

export const addUsernameOperation =
  ({ id, username }) =>
  (dispatch) => {
    dispatch(addUsernameRequest());
    postUsername({ id, username })
      .then((result) => {
        if (result.code === 409) {
          dispatch(addUsernameError(result.message));
        } else {
          dispatch(addUsernameSuccess(result.data));
        }
      })
      .catch((error) => {
        dispatch(addUserError(error.message));
      });
};

export const refreshSessionOperation = () => (dispatch) => {
  dispatch(refreshSessionRequest());
  refreshSession()
    .then((result) => {
      dispatch(refreshSessionSuccess(result.message));
      dispatch(addUserSuccess(result.userId));
    })
    .catch((error) => {
      dispatch(refreshSessionError(error.message));
    });
};

export const logoutOperation = () => (dispatch) => {
  logout()
    .then((result) => {
      dispatch(refreshSessionSuccess(result.message));
      dispatch(addUserSuccess([]));
      dispatch(getQrCodeSuccess([]));
      dispatch(whatsAppSession([]));
      dispatch(getCompaniesSuccess([]));
      dispatch(getCompaniesFromBDSuccess([]));
      disconnect();
    })
    .catch((error) => {
      console.log(error);
      dispatch(refreshSessionError(error.message));
    });
};

export const getCompaniesOperation = () => (dispatch) => {
  dispatch(getCompaniesRequest());
  getAllCompanies()
    .then((result) => {
      dispatch(getCompaniesSuccess(result.data.campaigns));
      dispatch(getCompaniesFromBDRequest());
      getCompaniesFromBd().then((result) => {
        dispatch(getCompaniesFromBDSuccess(result.data));
      });
    })
    .catch((error) => {
      dispatch(getCompaniesError(error.message));
    });
};

export const getCompaniesFromBDOperation = () => (dispatch) => {
  dispatch(getCompaniesFromBDRequest());
  getCompaniesFromBd()
    .then((result) => {
      dispatch(getCompaniesFromBDSuccess(result.data));
    })
    .catch((error) => {
      dispatch(getCompaniesFromBDError(error.message));
    });
};

export const deleteWhatsAppSessionOperation = () => (dispatch) => {
  dispatch(whatsAppSession("removing"))
  deleteSessionWhatsApp()
    .then(dispatch(getQrCodeSuccess('')))
    .then((result) => {
      dispatch(whatsAppSession(result.message))
    })
    .catch((error) => console.log(error.message));
};
