import { combineReducers } from "@reduxjs/toolkit";

import {
  userIdReducer,
  qrCodeReducer,
  refreshSessionReducer,
  userConflict,
  whatsApp,
  companiesUser,
  companiesUserFromBd,
  loading
} from "./user/user.reducer";

const rootReducer = combineReducers({
  userId: userIdReducer,
  qrCode: qrCodeReducer,
  session: refreshSessionReducer,
  errorMsg: userConflict,
  whatsAppSession: whatsApp,
  companies: companiesUser,
  companiesFromBd: companiesUserFromBd,
  loading
});

export default rootReducer;
