export const getUserId = (state) => state.userId;

export const getQrCode = (state) => state.qrCode;

export const getSession = (state) => state.session;

export const getErrorMsg = (state) => state.errorMsg;

export const whatsAppStatus = (state) => state.whatsAppSession;

export const getCompanies = (state) => state.companies;

export const getCompaniesFromBd = (state) => state.companiesFromBd;

export const getLoading = (state) => state.loading;