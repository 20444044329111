import io from "socket.io-client";
import { store } from "../redux/store";
import { getQrCodeSuccess, whatsAppSession } from "../redux/user/user.actions";

// const socket = io("localhost:4000", {
//   transports: ["websocket"]
// });

const socket = io("clientielite-automation.com/", {
  transports: ["websocket"]
});

// const socket = io("dev.clientielite-automation.com/", {
//   transports: ["websocket"]
// });

export function connect(userId) {
  socket.connect();

  const id = userId;
  socket.emit("id", id);

  socket.on("qr", (qr) => {
    store.dispatch(getQrCodeSuccess(qr));
    console.log("socket QR", socket.id);
  });

  socket.on("ready", (msg) => {
    store.dispatch(getQrCodeSuccess(msg));
  });

  socket.on("reload", (msg) => {
    window.location.reload();
  });

  socket.on("close", (msg) => {
    const url = new URL(window.location.href)
    window.location.href = url.origin;
  });

  socket.on("whats app session", (msg) => {
    store.dispatch(whatsAppSession(msg));
  });
}

export function qrClient(userId, qrClient) {
  socket.emit("qrClient", userId, qrClient)
}

export function disconnect() {
  console.log(socket.id);
  socket.disconnect();
}

export function disconnectWhatsApp(userId) {
  socket.emit("disconnect-whatsapp", userId);
}
