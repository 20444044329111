import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import AuthForm from "./components/authForm/AuthForm";
import AuthConnect from "./components/authConnect/AuthConnect";
import { refreshSessionOperation } from "./redux/user/user.operation";

import PrivateRoute from "./components/Route/PrivateRoute";
import PublicRoute from "./components/Route/PublicRoute";
import QrCode from "./components/qrCode/QrCode";
import CampaniesList from "./components/companies/CompaniesList";
import QrCodeClient from "./components/qrCode/qrCodeClient";
import Username from "./components/username/Username";
import { connect } from "./services/socket";
import {
  getUserId
} from "./redux/user/user.selectors";

function App() {
  const dispatch = useDispatch();
  
  const user = useSelector(getUserId);

  useEffect(() => {
    dispatch(refreshSessionOperation());
  }, [dispatch]);

  useEffect(() => {
    if(user)
      connect(user._id);
  }, [user]);

  return (
    <div>
      <Routes>
        <Route exat path="/" element={<PublicRoute />}>
          <Route path="/" element={<AuthForm />} />
        </Route>
        <Route exat path="/qr" element={<PrivateRoute />}>
          <Route exat path="/qr" element={<QrCode />} />
        </Route>
        <Route exat path="/username" element={<PrivateRoute />}>
          <Route path="/username" element={<Username />} />
        </Route>
        <Route exat path="/connect" element={<PrivateRoute />}>
          <Route path="/connect" element={<AuthConnect />} />
        </Route>
        <Route exat path="/companies" element={<PrivateRoute />}>
          <Route path="/companies" element={<CampaniesList />} />
        </Route>
        <Route exat path="/qrClient" element={<QrCodeClient />} />
      </Routes>

      
    </div>
  );
}

export default App;
