export const styleBtn = {
  iconStyleAddMsg: {
    width: "13px",
    height: "13px",
    marginRight: "6px",
    fill: "#4781E5",
  },

  btnStyleAddMsg: {
    width: "106px",
    height: "28px",
    fontSize: "12px",
    color: "#fff",
  },

  iconStyle: {
    width: "14px",
    height: "8px",
    marginRight: "6px",
    fill: "#8696A0",
  },

  iconStyleDel: {
    width: "10px",
    height: "10px",
    marginRight: "7px",
    fill: "#8696A0",
  },

  btnStyle: {
    width: "88px",
    height: "28px",
    marginRight: "16px",
    fontSize: "12px",
    color: "#8696A0",
  },

  btnStyleDel: {
    width: "132px",
    height: "28px",
    marginRight: "16px",
    fontSize: "12px",
    color: "#8696A0",
  },

  btnAddNewIcon: {
    fill: "#fff",
    width: "17px",
    height: "17px",
    marginRight: "10px",
  },

  btnAddNewStyle: {
    width: "115px",
    height: "36px",
    cursor: "pointer",
    color: "#fff",
    fontSize: "14px",
  },
};
