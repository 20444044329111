import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import style from "./CompaniesList.module.css";
import Header from "../header/Header";
import SelectCompany from "./SelectCompany";
import Company from "./Company";
import AddModal from "../modal/AddModal";
import BtnBlue from "../buttons/BtnBlue";
import { styleBtn } from "./styleBtnCompany";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanies,
  getLoading,
  whatsAppStatus,
  getUserId
} from "../../redux/user/user.selectors";
import {
  getCompaniesOperation,
  // getCompaniesFromBDOperation,
} from "../../redux/user/user.operation";
import Spiner from "../spiner/Spiner";


const CompaniesList = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const arrCompaniesFromGoHighLevel = useSelector(getCompanies);
  const statusWhatsApp = useSelector(whatsAppStatus);
  const loading = useSelector(getLoading);
  const user = useSelector(getUserId);
  
  useEffect(() => {
    dispatch(getCompaniesOperation());
    // dispatch(getCompaniesFromBDOperation());
  }, [dispatch]); 

  const togleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  let selectCompany = null;
  if (selectedCompany.id) {
    selectCompany =
      arrCompaniesFromGoHighLevel.find(
        (company) => company.id === selectedCompany.id
      ) || selectedCompany;
  }
  
  const chooseCompany = (e) =>
    setSelectedCompany(
      arrCompaniesFromGoHighLevel.find(
        (company) => company.id === e.currentTarget.id
      )
    );

  const showAllCompanies = () => {
    setSelectedCompany([]);
  };

  useEffect(() => {
    if(!user.username){
      navigate('/username')
    }
  }, [user]);

  return (
    <>
      <Header />
      {loading   ? (
        <div className={style.flexSpiner}>
          <Spiner style={{ color: "black", fontSize: "4em" }} />
        </div>
      ) : (
        <div className={style.container}>
          <div className={style.head}>
            <h1 className={style.headText}>My Campaigns</h1>
            <div onClick={togleModal}>
              <BtnBlue
                icon={"#icon-add"}
                iconStyle={styleBtn.btnAddNewIcon}
                btnStyle={styleBtn.btnAddNewStyle}
                name={"Add New"}
              />
            </div>
          </div>
          <SelectCompany
            showAllCompanies={showAllCompanies}
            arrCompaigns={arrCompaniesFromGoHighLevel}
            chooseCompany={chooseCompany}
            selectedCompany={selectedCompany}
          />
          <ul>
            {selectedCompany.id ? (
              <li>
                <Company company={selectCompany} />
              </li>
            ) : (
              arrCompaniesFromGoHighLevel.map((company) => (
                <li key={company.id}>
                  <Company company={company} />
                </li>
              ))
            )}
          </ul>
          {isOpenModal && (
            <AddModal
              name={"Add New WhatsApp Campaign"}
              togleModal={togleModal}
            />
          )}
        </div>
      )}

      {/* {statusWhatsApp === "active" || statusWhatsApp === "removing" ? (
        <>
          
        </>
      ) : (
        <Navigate to="/connect" />
      )} */}
    </>
  );
};

export default CompaniesList;
