export const styleBtn = {
  iconStyleCopy: {
    width: "20px",
    height: "10px",
    marginRight: "10px",
    fill: "#fff",
  },

  btnStyleCopy: {
    width: "151px",
    height: "48px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#fff",
  },
  iconStyleOpen: {
    width: "18px",
    height: "18px",
    marginRight: "10px",
    fill: "#fff",
  },

  btnStyleOpen: {
    width: "199px",
    height: "48px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#376dc9",
  },
};
